// extracted by mini-css-extract-plugin
export const smallContainerOverflow = "header-module--small-container-overflow--TZHT0";
export const smallContainerNonOverflow = "header-module--small-container-non-overflow--zlQmJ";
export const smallPhoneContainer = "header-module--small-phone-container--S4jqt";
export const smallPhoneIcon = "header-module--small-phone-icon--D21nt";
export const smallPhoneLink = "header-module--small-phone-link--rAqfB";
export const smallLogoBurgerContainer = "header-module--small-logo-burger-container--1V0uS";
export const smallLogoLink = "header-module--small-logo-link--2FYqA";
export const smallLogo = "header-module--small-logo--2AR2A";
export const smallHamburger = "header-module--small-hamburger--3STmh";
export const smallMenu = "header-module--small-menu--2c1jd";
export const topContainerOverlay = "header-module--top-container-overlay--3fztk";
export const container = "header-module--container--28qPq";
export const phoneContainer = "header-module--phone-container--2bgJ3";
export const phoneLink = "header-module--phone-link--2bHu9";
export const phoneLinkWhite = "header-module--phone-link-white--3DIXf";
export const phoneIcon = "header-module--phone-icon--2h3jB";
export const phoneIconWhite = "header-module--phone-icon-white--3NJvi";
export const menuContainer = "header-module--menu-container--LeG3w";
export const menuContent = "header-module--menu-content--22aIE";
export const logoLink = "header-module--logo-link--1CG3w";
export const logo = "header-module--logo--3pi_I";
export const spacerLeft = "header-module--spacer-left--2sezf";
export const spacerRight = "header-module--spacer-right--1eCqv";
export const spacerRightWhite = "header-module--spacer-right-white--_SqnS";
export const menu = "header-module--menu--2Uxwi";
export const menuWhite = "header-module--menu-white--uB8y0";
export const menuItemActive = "header-module--menu-item-active--32cvf";