import React from "react";
import {Link} from "gatsby";
import * as  styles from './footer.module.css';

export default class Footer extends React.Component {

    render() {
        return (
            <>
                <footer className={styles.footer}>
                    <div className={"container " + styles.container}>
                       <div className={"row"}>
                            <div className={"col-md-4"}>
                                <h3>Kronschläger Reisen</h3>
                                <address>
                                    Kapping 4<br />
                                    A-4723 Natternbach <br />
                                    +43 664 5030634<br />
                                    info@kronschlaeger-reisen.at
                                </address>
                            </div>
                           <div className={"col-md-4 " + styles.links}>
                               <Link to={"/logo-download"}>Logo Downloads</Link><br />
                               <Link to={"/datenschutz"}>Datenschutz</Link><br />
                               <Link to={"/impressum"}>Impressum</Link>
                           </div>
                       </div>
                    </div>
                </footer>
                <script async defer src="https://a.kronschlaeger-reisen.at/app.js"></script>
                <noscript><img src="https://a.kronschlaeger-reisen.at/image.gif" alt=""/></noscript>
            </>
        );
    }
}