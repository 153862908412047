import React from 'react';
import {Link} from "gatsby";
import * as styles from './header.module.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import logo from './logo.svg';

export default class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {collapsed: true};
    }

    render() {
        if (this.props.overlay) {
            return (
                <>
                    {this._smallScreenHeader()}
                    <div className={"d-none d-md-block " + styles.topContainerOverlay}>
                        <div className={"container " + styles.container}>
                            <div></div>
                            <div className={styles.phoneContainer}>
                                <i className={"fas fa-mobile-alt " + styles.phoneIconWhite}></i>
                                <a href={"tel:+436645030634"}
                                   className={styles.phoneLinkWhite}>0664 5030634</a>
                            </div>
                        </div>
                        <div className={styles.menuContainer}>
                            <div className={styles.spacerLeft}></div>
                            <div className={"container " + styles.menuContent}>
                                <Link to={"/"} className={styles.logoLink}><img className={styles.logo} src={logo}
                                                                                alt={"Kronschläger Reisen Logo"}/></Link>
                                <ul className={this.props.overlay ? styles.menuWhite : styles.menu}>
                                    <li><Link to={"/"} activeClassName={styles.menuItemActive}>Home</Link></li>
                                    <li><Link to={"/busflotte"} activeClassName={styles.menuItemActive}>Busflotte</Link>
                                    </li>
                                    <li><Link to={"/ueber-uns"} activeClassName={styles.menuItemActive}>Über Uns</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.spacerRightWhite}></div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    {this._smallScreenHeader()}
                    <div className={"d-none d-md-block"}>
                        <div className={"container " + styles.container}>
                            <div></div>
                            <div className={styles.phoneContainer}>
                                <i className={"fas fa-mobile-alt " + styles.phoneIcon}></i>
                                <a href={"tel:+436645030634"}
                                   className={styles.phoneLink}>0664
                                    5030634</a>
                            </div>
                        </div>
                        <div className={styles.menuContainer}>
                            <div className={styles.spacerLeft}></div>
                            <div className={"container " + styles.menuContent}>
                                <Link to={"/"} className={styles.logoLink}><img className={styles.logo} src={logo}
                                                                                alt={"Kronschläger Reisen Logo"}/></Link>
                                <ul className={this.props.overlay ? styles.menuWhite : styles.menu}>
                                    <li><Link to={"/"} activeClassName={styles.menuItemActive}>Home</Link></li>
                                    <li><Link to={"/busflotte"} activeClassName={styles.menuItemActive}>Busflotte</Link>
                                    </li>
                                    <li><Link to={"/ueber-uns"} activeClassName={styles.menuItemActive}>Über Uns</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.spacerRight}></div>
                        </div>
                    </div>
                </>
            );
        }
    }

    _smallScreenHeader() {
        return (
            <div
                className={"d-md-none " + (this.props.overlay ? styles.smallContainerOverflow : styles.smallContainerNonOverflow)}>
                {/* phone number */}
                <div className={styles.smallPhoneContainer}>
                    <i className={"fas fa-mobile-alt " + styles.smallPhoneIcon}></i>
                    <a href={"tel:+436645030634"}
                       className={styles.smallPhoneLink}>0664 5030634</a>
                </div>
                {/* logo + burger*/}
                <div className={styles.smallLogoBurgerContainer}>
                    <Link to={"/"} className={styles.smallLogoLink}><img className={styles.smallLogo} src={logo}
                                                                         alt={"Kronschläger Reisen Logo"}/></Link>
                    <i className={"fas fa-bars " + styles.smallHamburger} role={"button"} tabIndex={0}
                       onClick={() => this._toggleCollapsed()} onKeyDown={() => this._toggleCollapsed()}></i>
                </div>
                {/* Menu Items */}
                <ul className={(this.state.collapsed ? "d-none " : "") + styles.smallMenu}>
                    <li><Link to={"/"} activeClassName={styles.menuItemActive}>Home</Link></li>
                    <li><Link to={"/busflotte"} activeClassName={styles.menuItemActive}>Busflotte</Link>
                    </li>
                    <li><Link to={"ueber-uns"} activeClassName={styles.menuItemActive}>Über Uns</Link></li>
                </ul>
            </div>
        );
    }

    _toggleCollapsed() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
}